import React from 'react';

const PlayAudioButton = ({ color }) => {
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 134 134'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='67' cy='67' r='61' fill={color} />
      <path
        d='M20.4621 115.192C14.0629 109.012 8.95873 101.619 5.44733 93.4444C1.93593 85.2701 0.0876479 76.4784 0.0103422 67.5822C-0.0669635 58.686 1.62825 49.8634 4.99707 41.6294C8.36589 33.3953 13.3408 25.9147 19.6317 19.6238C25.9225 13.333 33.4031 8.35808 41.6372 4.98926C49.8713 1.62044 58.6938 -0.074776 67.59 0.0025297C76.4862 0.0798354 85.278 1.92811 93.4522 5.43952C101.626 8.95092 109.02 14.0551 115.2 20.4543C127.405 33.0907 134.158 50.015 134.005 67.5822C133.853 85.1494 126.806 101.954 114.384 114.376C101.962 126.798 85.1572 133.845 67.59 133.997C50.0228 134.15 33.0985 127.397 20.4621 115.192ZM105.753 105.745C115.811 95.6878 121.461 82.0468 121.461 67.8233C121.461 53.5998 115.811 39.9588 105.753 29.9013C95.6956 19.8438 82.0546 14.1935 67.8311 14.1935C53.6076 14.1935 39.9667 19.8438 29.9091 29.9013C19.8516 39.9588 14.2013 53.5998 14.2013 67.8233C14.2013 82.0468 19.8516 95.6878 29.9091 105.745C39.9667 115.803 53.6076 121.453 67.8311 121.453C82.0546 121.453 95.6956 115.803 105.753 105.745ZM47.7311 41.0233L101.331 67.8233L47.7311 94.6233V41.0233Z'
        fill='white'
      />
    </svg>
  );
};

export default PlayAudioButton;
