import React from 'react';

const StopAudioButton = ({ color }) => {
  return (
    <svg
      width='35'
      height='35'
      viewBox='0 0 146 146'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='73' cy='73' r='66' fill={color} />
      <path
        d='M73 0C53.6392 0 35.0714 7.69105 21.3812 21.3812C7.69105 35.0714 0 53.6392 0 73C0 92.3608 7.69105 110.929 21.3812 124.619C35.0714 138.309 53.6392 146 73 146C92.3608 146 110.929 138.309 124.619 124.619C138.309 110.929 146 92.3608 146 73C146 53.6392 138.309 35.0714 124.619 21.3812C110.929 7.69105 92.3608 0 73 0ZM73 132.312C57.2693 132.312 42.183 126.064 31.0597 114.94C19.9365 103.817 13.6875 88.7307 13.6875 73C13.6875 57.2693 19.9365 42.183 31.0597 31.0597C42.183 19.9365 57.2693 13.6875 73 13.6875C88.7307 13.6875 103.817 19.9365 114.94 31.0597C126.064 42.183 132.312 57.2693 132.312 73C132.312 88.7307 126.064 103.817 114.94 114.94C103.817 126.064 88.7307 132.312 73 132.312ZM45.625 45.625H100.375V100.375H45.625V45.625Z'
        fill='white'
      />
    </svg>
  );
};

export default StopAudioButton;
